import * as React from "react"
import { Link } from "gatsby"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"



const NotFoundPage = () => {

  return (

    <Layout SEOTitle={"404 Not Found - ICE"} darkThemeNav={true}>
      <div className="noPageFound">
        <div className="uk-text-center errorDetails">

          <h1>404</h1>
          <h3><b>Sorry, we can't find that page</b></h3>
          
          <div className="ctas" >
            <Link to="/" className="returnHomeBtn">Return home</Link>
          </div>

        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
